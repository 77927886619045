<template>
  <div class="page contact" :loaded="Boolean(locations && settings)">
    <ContactForm :ways="settings ? settings.ways : []" />

    <section ref="offices" class="offices" v-if="locations">
      <div class="wrapper">
        <h3>Our Offices</h3>
        <!-- <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5> -->
        <div class="container">
          <div class="office" v-for="location in locations" :key="location.id">
            <div class="office-info">
              <div class="office-name">
                {{location.name}}
              </div>
              <p>{{location.description}}</p>
              <h6>Address</h6>
              <div class="office-address">{{location.address}}</div>
              <h6>Contact details</h6>
              <div class="office-details">
                <div>TEL: {{location.phone}}</div>
                <div v-if="location.fax">FAX: {{location.fax}}</div>
                <div>EMAIL: {{location.email}}</div>
              </div>
              <div class="office-map" v-if="location.gmap">
                <Icon size="small" icon="map-marker-alt" type="fas" />
                Find your way to {{location.name}} on <a :href="location.gmap" target="_blank" rel="noopener noreferrer">Google Maps</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "Contact",
  components: {
    Icon,
    ContactForm,
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  data() {
    return {
      locations: null,
      settings: null,
    };
  },
  methods: {
    scrollOffices(e) {
      if (window.location.hash.includes('offices')) {
        if (e) {
          e.preventDefault();
        }
        this.$nextTick(() => {
          this.$refs.offices.scrollIntoView();
        });
        return false;
      }
    },
    getItems() {
      if (this.locationsRef) this.locationsRef();
      this.locationsRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}offices`)
        .orderBy("order")
        .onSnapshot((offices) => {
          this.locations = offices.docs.map((doc) => doc.data());
          this.scrollOffices();
        });
      this.settingsRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}settings/contact`)
        .onSnapshot((settings) => {
          this.settings = settings.data();
        });
    },
  },
  mounted: function () {
    this.getItems();
    window.addEventListener('hashchange', this.scrollOffices);
  },
  beforeDestroy: function () {
    window.removeEventListener('hashchange', this.scrollOffices);
    if (this.locationsRef) this.locationsRef();
    if (this.settingsRef) this.settingsRef();
  },
};
</script>

<style scoped>
.offices {
  position: relative;
  margin-top: 168px;
}

.offices h3 {
  color: #0A7E8C;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 48px;
}

.offices h5 {
  color: #656662;
  text-align: center;
}

.offices .container {
  display: flex;
  flex-wrap: wrap;
  gap: 64px 32px;
}

.offices .office {
  position: relative;
  width: calc(50% - 16px);
  border-radius: 12px;
  font-size: 1rem;
  overflow: hidden;
  transition: all 0.2s ease-out;
}
.offices .office:hover {
  box-shadow: 0px 24px 32px 0px rgba(14, 58, 90, 0.08);
}

.offices .office img {
  display: block;
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.offices .office .office-info {
  padding: 64px;
}

.offices .office .office-name {
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--Metallic-Seaweed);
  margin-bottom: 12px;
}

.offices .office p {
  position: relative;
  font-size: 1.25rem;
}

.offices .office .office-address {
  white-space: pre-wrap;
}

.offices .office h6 {
  margin: 24px 0 8px;
}

.offices .office .office-map {
  margin-top: 24px;
}

.offices .office .office-map .icon.small {
  width: auto;
  margin-right: 8px;
}

.offices .office .office-map a {
  color: #0A7E8C;
  text-decoration: underline;
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  .offices .office .office-info {
    padding: 48px 32px;
  }
  .offices .office p {
    height: auto;
  }
}

@media screen and (max-width: 920px) {
  .offices .container {
    justify-content: center;
  }
  .offices .office {
    width: 100%;
    max-width: 640px;
    margin-bottom: 24px;
  }
}
</style>
